<template>
  <div class="page">
    <div class="wrapper illustration illustration_point">
      <Backlink title="questionnaire" service="credit" backlink="/credit/mortgage" />
      <div class="content">
        <div class="content__container">
          <p class="question">{{ "c_s4p8_text_1" | localize }}</p>
          <form @submit.prevent>
            <div class="form__group">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_formatted"
                      class="v-input-calendar input_number"
                      v-mask.hide-on-empty="dateMask"
                      @focus="date = parseDate(date_formatted)"
                      @blur="date = parseDate(date_formatted); $v.date.$touch()"
                      :class="{ invalid: ($v.date.$dirty && !$v.date.required) || ($v.date.$dirty && !$v.date.validDate) }"
                      :hint="hintArray($v.date, [{rule: $v.date.required, message: 'form_reqiered'}, {rule: $v.date.validDate, message: 'form_wrong_date'}]) | localize"
                      :placeholder="'s1p2_date' | localize"
                      outlined
                      persistent-hint
                      append-icon="icon-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @change="checkForm($v.date)"
                  no-title
                  full-width
                  :max="dateFrom"
                  @input="menu = false; date_formatted = formatDate(date)"
                ></v-date-picker>
              </v-menu>
            </div>

            <p class="question">{{ "s2p7_text_4" | localize }}</p>
            <div class="form__group">
              <v-text-field
                v-model="price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.price)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('price', price)"
                :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                :hint="
                  ($v.price.$dirty && !$v.price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'c_s1p1_placeholder' | localize"
                append-icon="icon-₪"
                ref="price"
                outlined
              ></v-text-field>

              <v-text-field
                v-model.lazy="month_price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.month_price)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('month_price', month_price)"
                :class="{
                  invalid: $v.month_price.$dirty && !$v.month_price.required,
                }"
                :hint="
                  ($v.month_price.$dirty && !$v.month_price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :label="'s4p18_label_1' | localize"
                :placeholder="'s4p9_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="month_price"
                outlined
              ></v-text-field>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              @click="submitHandler"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const credit = VueCookies.get('credit') || {}

export default {
  name: 'Credit_date',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    date: credit.mortgage_date || null,
    price: credit.mortgage_price || null,
    month_price: credit.mortgage_month_price || null,
    dateFrom: new Date().toISOString().substr(0, 10),
    date_formatted: credit.mortgage_date ? this.formatDate(credit.mortgage_date || new Date().toISOString().substr(0, 10)) : null,
    dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
    menu: false,
    error: null
  }),
  created () {
    this.price = credit.mortgage_price ? this.formatNumber(credit.mortgage_price) : null
    this.month_price = credit.mortgage_month_price ? this.formatNumber(credit.mortgage_month_price) : null
  },
  validations: {
    date: { required, validDate: v => formMixin.methods.isValidDate(v) },
    price: { required },
    month_price: { required }
  },
  methods: {
    submitHandler () {
      this.price = this.parseNumber(this.price)
      this.month_price = this.parseNumber(this.month_price)

      if (!this.date || this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }
      credit.mortgage_date = this.date
      credit.mortgage_price = this.price
      credit.mortgage_month_price = this.month_price

      this.$cookies.set('credit', credit)
      this.saveServiceInfo('credit', { name: 'Credit clients', params: { clients: 2 } }, {
        mortgage_date: this.date,
        mortgage_price: this.price,
        mortgage_payment: this.month_price
      })
    }
  }
}
</script>
